<template>
    <div class="cs-block cs-contact-form-block">
        <div class="cs-base-block">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5">
                        <div class="info-wrapper">
                            <h2 class="cs-title" v-if="data.title">
                                <ColorSplit :data="data.title" />
                            </h2> 
                            <h3 class="cs-sub-title" v-if="data.subTitle">{{data.subTitle}}</h3>
                            <span class="cs-text" v-if="data.text" v-html="data.text"></span>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <CSForm :id="data.id" ></CSForm>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {computed} from '../libs/common-fn';

    export default {
        name: "ContactForm",
        components: { 
            ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
            CSForm: () => import("../components/CSForm.vue") 
        },
        props: {
            data: {
                type: Object,
            default: () => { },
            }
        },
        data() {
            return {}
        },
        methods: {
            submitForm(){
                console.log('submited')
            }
        },
        computed: computed('ContactForm')
    }

</script>
<style scoped lang="scss">
    @import "../styles/main.scss";

    .cs-contact-form-block{
        .info-wrapper {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

</style>